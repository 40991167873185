import Button from "../../components/Button";
import searchIcon from "../../../assets/searchIcon.svg";
import lines from "../../../assets/lines.svg";
import blocks from "../../../assets/blocks.svg";
import { useState, useEffect } from "react";
import RecentProjects from "./RecentProjects";
import { Upload } from "antd";
import { addOrUpdateProject, getAllProjects } from "../fakeApi";
import { useUserIdentity } from "../../../hooks/useUserIdentity";
import { PlusCircleOutlined } from "@ant-design/icons";
import { AddProjectModal } from "./AddProjectModal";

const ProjectsMain = () => {
  const [projects, setProJects] = useState([]);
  const [search, setSearch] = useState("");
  const [projectsView, setProjectsView] = useState("grid");
  const { canAccessDiagram } = useUserIdentity();
  const [isAddModalopen, setIsAddModalOpen] = useState(false);

  const getProjects = () => {
    if (canAccessDiagram) setProJects(getAllProjects()?.reverse());
  };

  const onChange = (options) => {
    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(options.file);
  };

  const onReaderLoad = (event) => {
    var projectData = JSON.parse(event.target.result);
    if (projectData.store) {
      addOrUpdateProject(projectData, projectData.tempProject.id);
    } else if (projectData && !projectData.store) {
      addOrUpdateProject(projectData, projectData.id);
    }
    getProjects();
  };

  useEffect(() => {
    getProjects();
  }, [canAccessDiagram]);

  return (
    <>
      <main className="flex flex-col grow max-w-full w-[50%]">
        <div className="flex justify-between items-center gap-[24px] sm:gap-0 border-b">
          <p className="font-medium text-[20px] pl-8">My Projects</p>
          <div className="flex items-center gap-8 flex-col sm:flex-row py-2 pr-8 ">
            <Button
              onClick={() => setIsAddModalOpen(true)}
              className={`btn h-10 element-center gap-[10px] rounded-lg py-[10px] px-5`}
            >
              <span>
                <PlusCircleOutlined />
              </span>
              <span className="text-[10px] sm:text-[14px] font-medium">
                New Project
              </span>
            </Button>
            {canAccessDiagram && (
              <Upload showUploadList={false} customRequest={onChange}>
                <Button className="h-10 bg-[#EBF1FF] rounded-lg py-[10px] px-5 text-gray-600 font-medium text-[14px]">
                  Import
                </Button>
              </Upload>
            )}
          </div>
        </div>
        {canAccessDiagram && (
          <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 items-center justify-between px-8 py-8">
            <div className="flex gap-4 border rounded-lg items-center px-4 py-[14px] w-full sm:w-[360px]">
              <span>
                <img src={searchIcon} alt="searchIcon" />
              </span>
              <input
                placeholder="Search"
                className="outline-none w-full max-w-full"
                onChange={(e) => setSearch(e.target?.value)}
              />
            </div>
            <div className="flex gap-10 items-center">
              <div className="flex gap-4 [&>_.active]:bg-[#F4F5F7]">
                <span
                  onClick={() => setProjectsView("grid")}
                  className={
                    projectsView === "grid"
                      ? "active rounded"
                      : "cursor-pointer"
                  }
                >
                  <img src={blocks} alt="blocks" />
                </span>
                <span
                  onClick={() => setProjectsView("list")}
                  className={
                    projectsView === "list"
                      ? "active rounded"
                      : "cursor-pointer"
                  }
                >
                  <img src={lines} alt="lines" />
                </span>
              </div>
            </div>
          </div>
        )}
        <RecentProjects
          getProjects={getProjects}
          projects={projects?.filter?.((project) =>
            search ? project?.metaData.name?.includes(search) : true
          )}
          setIsAddModalOpen={setIsAddModalOpen}
          projectsView={projectsView}
        />
      </main>
      <AddProjectModal isOpen={isAddModalopen} setIsOpen={setIsAddModalOpen} />
    </>
  );
};

export default ProjectsMain;

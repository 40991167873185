import Button from "../../components/Button";
import logo from "../../../assets/codey-logo.png";
// import layerlogo from "../../../assets/Layer6.svg";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../../../atoms/auth.atom";

function Headers() {
  const navigate = useNavigate();
  const [userData, setUserData] = useRecoilState(userDataAtom);
  return (
    <header className="pt-[32px]">
      <div className="container flex justify-between items-center gap-[24px] sm:gap-0 border border-x-transparent ">
        <span className="flex cursor-pointer" onClick={() => navigate("/")}>
          {/* <img
            className="relative -left-7 m-4"
            src={layerlogo}
            alt="layerlogo-img"
            loading="lazy" -left-7
          /> */}
          <img className="relative " src={logo} alt="logo-img" />
        </span>
        <div className="flex items-center gap-[24px] flex-col sm:flex-row py-[12px] ">
          {!!userData ? (
            <>
              <Button
                onClick={() => {
                  setUserData(null);
                  localStorage.removeItem("userData");
                }}
                className="text-[#3F67AD] font-medium text-lg leading-[26px] hidden sm:block"
              >
                Logout
              </Button>
              <Button
                onClick={() => navigate("/codeyai/projects")}
                className={`btn`}
              >
                Projects
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => navigate("/codeyai/projects")}
                type="submit"
                className="text-[#3F67AD] font-medium text-lg leading-[26px] hidden sm:block"
              >
                Project Initializer
              </Button>
              <Button
                onClick={() => navigate("/login")}
                type="submit"
                className="text-[#3F67AD] font-medium text-lg leading-[26px] hidden sm:block"
              >
                Login
              </Button>
              <Button
                onClick={() => navigate("/codeyai/projects")}
                className={`btn`}
              >
                Try for free
              </Button>
            </>
          )}
        </div>
      </div>
    </header>
  );
}

export default Headers;
